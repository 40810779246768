import { FieldType, PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { ButtonPanel } from './ButtonPanel';

export const plugin = new PanelPlugin<SimpleOptions>(ButtonPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'timeFromOption',
      path: 'timeFromOption',
      name: 'Time From Field',
      description: 'This will set the "From" part of the time range.',
      defaultValue: 'time_from',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      settings: {
        filterByType: [FieldType.number],
      },
      showIf: config => config.TypePickerButton === true && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'timeToOption',
      path: 'timeToOption',
      name: 'Time To Field',
      description: 'This will set the "To" part of the time range.',
      defaultValue: 'time_to',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      settings: {
        filterByType: [FieldType.number],
      },
      showIf: config => config.TypePickerButton === true && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonStyleOption',
      path: 'buttonStyleOption',
      name: 'Style Field',
      description: 'The button color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'button_style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
	  showIf: config => config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonIconOption',
      path: 'buttonIconOption',
      name: 'Icon Field',
      description: 'The field that will be used as the Icon for the button',
      defaultValue: 'button_icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      showIf: config => config.TypePickerButton === false && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonTextOption',
      path: 'buttonTextOption',
      name: 'Text Field',
      description: 'The field that will be used as the Text for the button',
      defaultValue: 'button_text',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
	  showIf: config => config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonVariableOption',
      path: 'buttonVariableOption',
      name: 'Variable Field',
      description: 'The variable modified by the button. If variable name is url and value has a valid url, the button opens the url link in a blank page.',
      defaultValue: 'button_variable',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      showIf: config => config.TypePickerButton === false && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonValueOption',
      path: 'buttonValueOption',
      name: 'Value Field',
      description: 'The new value to be asigned',
      defaultValue: 'button_value',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      showIf: config => config.TypePickerButton === false && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonResultOption',
      path: 'buttonResultOption',
      name: 'Result Field',
      description: 'The field that will be used as the indicator inside the button',
      defaultValue: 'button_result',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      showIf: config => config.TypePickerButton === false && config.displayStyle !== 'input',
    })
    .addCustomEditor({
      id: 'buttonTitleOption',
      path: 'buttonTitleOption',
      name: 'Tooltip Field',
      description: 'The tooltip shows with the button',
      defaultValue: 'button_title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
      showIf: config => config.TypePickerButton === false && config.displayStyle !== 'input',
    })

    .addSelect({
      path: 'displayStyle',
      name: 'Style',
      description: 'Visual presentation of the time picker',
      category: ['General Settings'],
      defaultValue: 'button',
      settings: {
        options: [
          {
            value: 'button',
            label: 'Button',
          },
          {
            value: 'dropdown',
            label: 'Dropdown',
          },
          {
            value: 'input',
            label: 'Input',
          },
          {
            value: 'filter',
            label: 'Filter',
          },
        ],
      },
    })
    .addTextInput({
      path: 'minWidth',
      name: 'Button mode min width',
      description: 'Switch to dropdown mode when panel width in minor to this threshold',
      category: ['General Settings'],
	  showIf: config => config.displayStyle === 'button',
      defaultValue: '250',
    })
    .addBooleanSwitch({
      path: 'toLeft',
      name: 'Align elements to Left ?',
      category: ['General Settings'],
	  defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'TimePickerInput',
      name: 'Time Picker Input',
      description: 'If it is active, shows a timepicket input',
      category: ['General Settings'],
      showIf: config => config.displayStyle === 'input',
	  defaultValue: false,
    })
    .addSelect({
      path: 'inputType',
      name: 'Type',
      description: 'Input type according to html standard',
      category: ['Input mode'],
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === false,
	  defaultValue: 'text',
      settings: {
        options: [
          {
			value: 'button',
			label: 'Button',
		  },
		  {
			value: 'checkbox',
			label: 'Checkbox',
		  },
		  {
			value: 'color',
			label: 'Color',
		  },
		  {
			value: 'date',
			label: 'Date',
		  },
		  {
			value: 'datetime-local',
			label: 'Datetime-local',
		  },
		  {
			value: 'email',
			label: 'Email',
		  },
		  {
			value: 'month',
			label: 'Month',
		  },
		  {
			value: 'number',
			label: 'Number',
		  },
		  {
			value: 'password',
			label: 'Password',
		  },
		  {
			value: 'radio',
			label: 'Radio',
		  },
	      {
			value: 'range',
			label: 'Range',
		  },
		  {
			value: 'reset',
			label: 'Reset',
		  },
		  {
			value: 'tel',
			label: 'Tel',
		  },
		  {
			value: 'text',
			label: 'Text',
		  },
		  {
			value: 'time',
			label: 'Time',
		  },
		  {
			value: 'url',
			label: 'Url',
		  },
		  {
			value: 'week',
			label: 'Week',
		  },
        ],
      },
    })
    .addTextInput({
      path: 'inputVariable',
      name: 'Variable to modify',
      description: 'The variable name to modify when user enter a text in the input box. If timepicket use from or to as variable name',
      category: ['Input mode'],
      defaultValue: 'filter',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === false,
    })
    .addTextInput({
      path: 'inputFromVariable',
      name: 'Variable name  to modify in From input',
      description: 'The variable name to modify when user enter a new date in from input in timepicket mode.',
      category: ['General Settings'],
      defaultValue: 'from',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === true,
    })
    .addTextInput({
      path: 'inputToVariable',
      name: 'Variable name  to modify in To input',
      description: 'The variable name to modify when user enter a new date in to input in timepicket mode.',
      category: ['General Settings'],
      defaultValue: 'to',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === true,
    })
    .addTextInput({
      path: 'inputDefaultValue',
      name: 'Default value',
      description: 'Value to use when input text is empty',
      category: ['Input mode'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === false,
    })
    .addTextInput({
      path: 'inputText',
      name: 'Input Title',
      description: 'Title use when input mode.',
      category: ['Input mode'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === false,
    })
    .addTextInput({
      path: 'inputFromText',
      name: 'From Input Title',
      description: 'Title use in From input select.',
      category: ['Input mode'],
      defaultValue: 'Desde',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === true,
    })
    .addTextInput({
      path: 'inputToText',
      name: 'To Input Title',
      description: 'Title use in To input select.',
      category: ['Input mode'],
      defaultValue: 'Hasta',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === true,
    })
    .addTextInput({
      path: 'placeholderText',
      name: 'Placeholder text',
      description: 'Placeholder text use when variable value is empty.',
      category: ['Input mode'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'input' && config.TimePickerInput === false,
    })

    .addTextInput({
      path: 'variableText',
      name: 'DropDown Title',
      description: 'Title use in dropdown.',
      category: ['DropDown mode'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'dropdown',
    })
    .addBooleanSwitch({
      path: 'allOption',
      name: 'All option',
      description: 'Enable All option in dropdown mode',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.multiValue === false && config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'defaultPlaceholder',
      name: 'Placeholder',
      description: 'Placeholder used in dropdown mode when All option is disabled',
      category: ['DropDown mode'],
      defaultValue: '',
      showIf: config => config.multiValue === false && config.displayStyle === 'dropdown' && config.allOption === false,
    })
    .addTextInput({
      path: 'allOptionVariable',
      name: 'All option Variable',
      description: 'Variable to use in All option selection',
      category: ['DropDown mode'],
      defaultValue: 'all',
      showIf: config => config.allOption === true,
    })
    .addTextInput({
      path: 'allOptionText',
      name: 'All option Value',
      description: 'Value to use as All option in dropdown mode',
      category: ['DropDown mode'],
      defaultValue: 'Todos',
      showIf: config => config.allOption === true,
    })
    .addBooleanSwitch({
      path: 'selectFisrt',
      name: 'Select First Option',
      description: 'Select first option available automatically when no option is selected or track variable is empty',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.multiValue === false && config.displayStyle === 'dropdown',
    })
    .addBooleanSwitch({
      path: 'cleanDefaultVariable',
      name: 'Clean a variable',
      description: 'Enable the option to clean a variable to refresh another dropdown',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'dropdown',
    })
    .addBooleanSwitch({
      path: 'cleanDefaultVariableIsMulti',
      name: 'is MultiValue?',
      description: 'Variable to clean when is multi Value variable? (CUSTOM variable with MultiValue option enable)',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.cleanDefaultVariable === true,
    })
    .addTextInput({
      path: 'variableToClean',
      name: 'Variable to clean',
      description: 'The variable name to clean',
      category: ['DropDown mode'],
      defaultValue: '',
      showIf: config => config.cleanDefaultVariable === true,
    })
    .addTextInput({
      path: 'defaultValuetoClean',
      name: 'Default value when Clean',
      description: 'Value to use when clean a variable',
      category: ['DropDown mode'],
      defaultValue: '',
      showIf: config => config.cleanDefaultVariable === true,
    })
    .addBooleanSwitch({
      path: 'multiValue',
      name: 'Multi Select enable',
      description: 'Allow more than one value to select in the dropdown mode',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'maxOptionsLimit',
      name: 'Max selection allowed',
      description: 'Define the maximum options the user can select',
      category: ['DropDown mode'],
      defaultValue: '5',
      showIf: config => config.multiValue === true && config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'multiValueVariable',
      name: 'Multi Value Variable',
      description: 'Variable used as multivalue select. The variable must be define as CUSTOM type with MultiValue option enable.',
      category: ['DropDown mode'],
      defaultValue: 'filter',
      showIf: config => config.multiValue === true && config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'defaultValueDropdown',
      name: 'Default variable value',
      description: 'Value to use as default for default Multi Value Variable',
      category: ['DropDown mode'],
      defaultValue: 'Todos',
      showIf: config => config.multiValue === true && config.displayStyle === 'dropdown',
    })
    .addBooleanSwitch({
      path: 'enableSearch',
      name: 'Enable search field',
      description: 'Add a search field next to dropdown',
      category: ['DropDown mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'searchVariable',
      name: 'Search Variable',
      description: 'Variable to update when user ingress text on search container',
      category: ['DropDown mode'],
      defaultValue: 'search2',
	  showIf: config => config.displayStyle === 'dropdown' && config.enableSearch === true,
    })
    .addTextInput({
      path: 'searchTitle',
      name: 'Search Tooltip',
      category: ['DropDown mode'],
      defaultValue: 'Buscar en NetMonitor',
	  showIf: config => config.displayStyle === 'dropdown' && config.enableSearch === true,
    })
    .addTextInput({
      path: 'filterDefaultValue',
      name: 'Search Filter Default Value',
      description: 'Default value in search filter',
      category: ['DropDown mode'],
      defaultValue: 'Todos',
	  showIf: config => config.displayStyle === 'dropdown' && config.enableSearch === true,
    })

    .addTextInput({
      path: 'buttonText',
      name: 'Button Title',
      description: 'Title use in button mode.',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'button',
    })
    .addBooleanSwitch({
      path: 'zoomButton',
      name: 'Zoom Button',
      description: 'If it is active, shows zoom icon',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'button' && config.TypePickerButton === false && config.displayButtonsHorizontal,
    })
    .addTextInput({
      path: 'zoomButtonUrl',
      name: 'Zoom Button',
      description: 'link to open when press the Zoom Button',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.zoomButton === true && config.displayStyle === 'button' && config.displayButtonsHorizontal,
    })
    .addBooleanSwitch({
      path: 'cleanButton',
      name: 'Clean Button',
      description: 'If it is active, shows Clean icon',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'button' && config.displayButtonsHorizontal,
    })
    .addTextInput({
      path: 'defaultVariable',
      name: 'Default Variable',
      description: 'Variable to use in clean action. The variable must be define as CUSTOM type',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.cleanButton === true && config.displayStyle === 'button',
    })
    .addTextInput({
      path: 'defaultValue',
      name: 'Default Value',
      description: 'Value to assign to default variable when press Clean icon',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.cleanButton === true && config.displayStyle === 'button',
    })
    .addBooleanSwitch({
      path: 'mapButton',
      name: 'Map type Button',
      description: 'If it is active, shows a button to change kinf of map',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'button' && config.displayButtonsHorizontal,
    })
    .addTextInput({
      path: 'mapVariable',
      name: 'Map Variable',
      description: 'Variable to use in change map action. The variable must be define as CUSTOM type',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.mapButton === true,
    })
    .addBooleanSwitch({
      path: 'heatButton',
      name: 'Heat Button',
      description: 'If it is active, shows a button to enable heatmap',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.mapButton === true,
    })
    .addTextInput({
      path: 'heatVariable',
      name: 'Heatmap Variable',
      description: 'Variable to enable heat map option. The variable must be define as CUSTOM type',
      category: ['Button mode'],
      defaultValue: '',
      showIf: config => config.heatButton === true,
    })
    .addTextInput({
      path: 'selectedTooltip',
      name: 'Selected button Tooltip',
      description: 'Tooltip use in selected button',
      category: ['Button mode'],
      showIf: config => config.displayStyle === 'button',
      defaultValue: 'Filtro aplicado actualmente',
    })
    .addBooleanSwitch({
      path: 'displayButtonsHorizontal',
      name: 'Horizontal Buttons',
      description: 'Display buttons horizontally.',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'button',
    })
    .addBooleanSwitch({
      path: 'displayFullWidthButtons',
      name: 'Full Width Buttons',
      description: 'Display full width Buttons in vertical mode.',
      category: ['Button mode'],
      defaultValue: false,
      showIf: config => config.displayButtonsHorizontal === false && config.displayStyle === 'button',
    })
    .addTextInput({
      path: 'primaryFieldButton',
      name: 'Primary Button',
      description: 'The field used to determine which button is highlighted',
      defaultValue: '0',
      category: ['Button mode'],
      showIf: config => config.displayStyle === 'button',
    }) 
    .addBooleanSwitch({
      path: 'TypePickerButton',
      name: 'Time Picker Button',
      description: 'If it is active, shows a timepicket button',
      category: ['General Settings'],
      showIf: config => config.displayStyle === 'button',
	  defaultValue: false,
    })
    .addTextInput({
      path: 'variableToTrack',
      name: 'Main variable to Track',
      description: 'This field is used to determine if plugin need to be re-renderized',
      defaultValue: '',
      category: ['General Settings'],
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen opciones',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
