import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { AlarmThreshold } from './AlarmThreshold';
import { AlarmThresholdGroup } from './AlarmThresholdGroup';

export const AlarmThresholdManagerPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const userName = contextSrv.user.name;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const showTitle = options.showTitle;
  const showSelector = options.showAssetSelector;
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen firmas';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);
  const assetVariable = replaceVariables(options.assetVariable);
  const assetTypesVariable = replaceVariables(options.assetTypesVariable);
  const pluginVariables = [pluginApi, assetVariable, assetTypesVariable];
  const [thresholdsConfigured, setThresholdsConfigured] = useState<FormSmThreshold[]>([]);
  const [valueTracked, setValueTracked] = useState('');
  const [renderCount, setRenderCount] = useState(0);

  const memoizedOptions = useMemo(() => ({ 
    data: data,
	filterToTrack: replaceVariables(options.variableToTrack),
  }), [data, options, replaceVariables]);
  const filterUsed = memoizedOptions.filterToTrack;
 
  const assetList: [] = [];
  const assetVariableValue = replaceVariables(options.assetList);
  const assets = assetVariableValue.split(',');

  for (let i = 0; i < assets.length; i++) {
	const assetData = assets[i].split('||');
	let asset = {
	  label: assetData[0],
	  description: assetData[1],
	  family: assetData[2],
	  value: assetData[0],
	};
	assetList.push(asset);
  }

  const assetTypeList: [] = [];
  const assetTypeVariableValue = replaceVariables(options.assetTypes);
  const assetTypes = assetTypeVariableValue.split(',');

  for (let i = 0; i < assetTypes.length; i++) {
	const assetTypeData = assetTypes[i].split('||');
	let assetType = {
	  label: assetTypeData[0],
	  description: assetTypeData[1],
	  value: assetTypeData[0],
	};
	assetTypeList.push(assetType);
  }

  const thresholdTypeList: [] = [];
  const thresholdTypeVariableValue = replaceVariables(options.thresholdTypes);
  const thresholdTypes = thresholdTypeVariableValue.split(',');

  for (let i = 0; i < thresholdTypes.length; i++) {
	const thresholdTypeData = thresholdTypes[i].split('||');
	let thresholdType = {
	  label: thresholdTypeData[0],
	  description: thresholdTypeData[1],
	  value: thresholdTypeData[2],
	};
	thresholdTypeList.push(thresholdType);
  }

  const stringToType = (name: string) => {
	const typeSelected = thresholdTypeList.find(element => element.label === name);
	const valueToReturn = typeSelected ? typeSelected.value : null;
	return valueToReturn;
  }

  const newThresholdConfigured: AlarmThreshold[] = [];
  memoizedOptions.data.series.forEach(series => {
	const rulesVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < rulesVals.length; i++) {
	  let newThreshold = {
		id: i,
		thresholdAsset: series.fields.find(field => field.name === options.thresholdAsset)?.values.get(i),
		thresholdType: series.fields.find(field => field.name === options.thresholdType)?.values.get(i),
		thresholdIsBoolean: series.fields.find(field => field.name === options.thresholdIsBoolean)?.values.get(i),
		thresholdState: series.fields.find(field => field.name === options.thresholdState)?.values.get(i),
		thresholdPercentFail: series.fields.find(field => field.name === options.thresholdPercentFail)?.values.get(i),
		thresholdBelow: !series.fields.find(field => field.name === options.thresholdBelow)?.values.get(i),
		thresholdDual: series.fields.find(field => field.name === options.thresholdDual)?.values.get(i),
		thresholdUp: series.fields.find(field => field.name === options.thresholdUp)?.values.get(i),
		thresholdDown: series.fields.find(field => field.name === options.thresholdDown)?.values.get(i),
		notifRepeat: series.fields.find(field => field.name === options.notifRepeat)?.values.get(i),
		thresholdSource: series.fields.find(field => field.name === options.thresholdSource)?.values.get(i),
		thresholdDate: series.fields.find(field => field.name === options.thresholdDate)?.values.get(i),
		thresholdUser: series.fields.find(field => field.name === options.thresholdUser)?.values.get(i),
	  };
	  const thresholdDate = newThreshold.thresholdDate;
	  newThreshold.thresholdDate = moment(thresholdDate).format('DD/MM/YYYY HH:mm:ss');
	  newThresholdConfigured.push(newThreshold);
	}
  });

  if (renderCount === 0 || valueTracked !== memoizedOptions.filterToTrack) {
    setThresholdsConfigured(newThresholdConfigured);
	setValueTracked(memoizedOptions.filterToTrack);
	setRenderCount(renderCount + 1);
  }

  const noAlertsMessage = thresholdsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error' || noAlertsMessage) {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <AlarmThresholdGroup
	    id={id + '_' + renderCount}
	    showTitle={showTitle}
		showSelector={showSelector}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    thresholds={thresholdsConfigured}
		assets={assetList}
		thresholdTypeList={thresholdTypeList}
		types={assetTypeList}
		pluginVariables={pluginVariables}
		onSaveChanges={(thresholdSaved) => {
		  const actualThresholds = thresholdsConfigured;
		  const threshold = thresholdSaved.find(ele => ele.id !== undefined);
		  const index = threshold ? threshold.id : null;
		  const thresholdType = stringToType(threshold.thresholdType);
		  if (index !== null && thresholdType !== null) {
			const d = Date.now();
			const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
			const newThreshold = {
			  id: threshold.id,
			  thresholdAsset: threshold.asset,
			  thresholdType: thresholdType,
			  thresholdIsBoolean: threshold.isBoolean,
			  thresholdState: threshold.state,
			  thresholdPercentFail: Number(threshold.percentFail),
			  thresholdBelow: threshold.thresholdBelow,
			  thresholdDual: threshold.thresholdDual,
			  thresholdUp: Number(threshold.thresholdUp),
			  thresholdDown: Number(threshold.thresholdDown),
			  notifRepeat: threshold.notifRepeat,
			  thresholdSource: 'asset',
			  thresholdDate: date,
			  thresholdUser: userName,
			};
			actualThresholds[index] = newThreshold;
		    setThresholdsConfigured(actualThresholds);			
		  }
		  return actualThresholds;
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: 4px;
  `,
});
