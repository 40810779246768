import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { NetMonitorTheme2 } from '@grafana/data';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { CustomFormSmSign } from './CustomSmSigns';
import { CustomSignGroup } from './CustomSignGroup';

export const CustomSignGroupsPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const styles = useStyles2(getStyles);
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : contextSrv.isNetMonitorAdmin;
  const pluginTitle = replaceVariables(options.pluginTitle);
  const showTitle = options.showTitle;
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen firmas';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);
  const assetVariable = replaceVariables(options.assetVariable);
  const oidVariable = replaceVariables(options.oidVariable);
  const pluginVariables = [pluginApi, assetVariable, oidVariable];
  const [signsConfigured, setSignsConfigured] = useState<FormSmSign[]>([]);
  const [valueTracked, setValueTracked] = useState('');
  const [renderCount, setRenderCount] = useState(0);

  const memoizedOptions = useMemo(() => ({ 
    data: data,
	filterToTrack: replaceVariables(options.variableToTrack),
  }), [data, options, replaceVariables]);
  const filterUsed = memoizedOptions.filterToTrack;
 
  const assetList: [] = [];
  const assetVariableValue = replaceVariables(options.assetList);
  const assets = assetVariableValue.split(',');

  for (let i = 0; i < assets.length; i++) {
	const assetData = assets[i].split('||');
	let asset = {
	  label: assetData[0],
	  description: assetData[1],
	  family: assetData[2],
	  value: assetData[0],
	};
	assetList.push(asset);
  }

  const assetTypeList: [] = [];
  const assetTypeVariableValue = replaceVariables(options.assetTypes);
  const assetTypes = assetTypeVariableValue.split(',');

  for (let i = 0; i < assetTypes.length; i++) {
	const assetTypeData = assetTypes[i].split('||');
	let assetType = {
	  label: assetTypeData[0],
	  description: assetTypeData[1],
	  value: assetTypeData[0],
	};
	assetTypeList.push(assetType);
  }

  const signTypeList: [] = [];
  const signTypeVariableValue = replaceVariables(options.signTypes);
  const signTypes = signTypeVariableValue.split(',');

  for (let i = 0; i < signTypes.length; i++) {
	const signTypeData = signTypes[i].split('||');
	let signType = {
	  label: signTypeData[0],
	  description: signTypeData[1],
	  value: signTypeData[2],
	};
	signTypeList.push(signType);
  }

  const newSignsConfigured: CustomFormSmSign[] = [];
  memoizedOptions.data.series.forEach(series => {
	const rulesVals: GraphSeriesValue[] = series.fields[0].values.toArray();
	for (let i = 0; i < rulesVals.length; i++) {
	  let newSign = {
		id: i,
		signId: series.fields.find(field => field.name === options.signId)?.values.get(i),
		signAsset: series.fields.find(field => field.name === options.signAsset)?.values.get(i),
		signOid: series.fields.find(field => field.name === options.signOid)?.values.get(i),
		signOidValueType: series.fields.find(field => field.name === options.signOidValueType)?.values.get(i),
		signOidThresholdBoolean: false,
		signThresholdBelow: !series.fields.find(field => field.name === options.signThresholdBelow)?.values.get(i),
		signDualThreshold: series.fields.find(field => field.name === options.signDualThreshold)?.values.get(i),
		signOidThresholdUp: series.fields.find(field => field.name === options.signOidThresholdUp)?.values.get(i),
		signOidThresholdDown: series.fields.find(field => field.name === options.signOidThresholdDown)?.values.get(i),
		signName: series.fields.find(field => field.name === options.signName)?.values.get(i),
		signType: series.fields.find(field => field.name === options.signType)?.values.get(i),
		signState: series.fields.find(field => field.name === options.signState)?.values.get(i),
		signDate: series.fields.find(field => field.name === options.signDate)?.values.get(i),
		signUser: series.fields.find(field => field.name === options.signUser)?.values.get(i),
		alertName: series.fields.find(field => field.name === options.alertName)?.values.get(i),
		alertSummary: series.fields.find(field => field.name === options.alertSummary)?.values.get(i),
		resolSummary: series.fields.find(field => field.name === options.resolSummary)?.values.get(i),
		alertSeverity: series.fields.find(field => field.name === options.alertSeverity)?.values.get(i),
		alertScope: series.fields.find(field => field.name === options.alertScope)?.values.get(i),
		openTicket: series.fields.find(field => field.name === options.openTicket)?.values.get(i),
	  };
	  newSign.signOidThresholdBoolean = newSign.signOidThresholdUp === 'true' ? true : false;
	  const signDate = newSign.signDate;
	  newSign.signDate = moment(signDate).format('DD/MM/YYYY HH:mm:ss');
	  newSignsConfigured.push(newSign);
	}
  });

  if (renderCount === 0 || valueTracked !== memoizedOptions.filterToTrack) {
    setSignsConfigured(newSignsConfigured);
	setValueTracked(memoizedOptions.filterToTrack);
	setRenderCount(renderCount + 1);
  }

  const noAlertsMessage = signsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error' || noAlertsMessage) {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <CustomSignGroup
	    id={id + '_' + renderCount}
	    showTitle={showTitle}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    signs={signsConfigured}
		assets={assetList}
		signTypeList={signTypeList}
		types={assetTypeList}
		pluginVariables={pluginVariables}
		onSaveChanges={(signSaved) => {
		  const actualSigns = signsConfigured;
		  const sign = signSaved.find(sign => sign.idx !== undefined);
		  if (sign) {
		    const index = sign.id;
			const newSign = {
			  id: sign.id,
			  signId: sign.idx,
			  signName: sign.sign,
			  signState: sign.state,
			  signDate: sign.date,
			  signUser: sign.user,
			  signAsset: sign.asset,
			  signOid: sign.oid,
			  signOidValueType: sign.oidValueType,
			  signThresholdBelow: sign.oidThresholdBelow,
			  signDualThreshold: sign.oidDualThreshold,
			  signOidThresholdBoolean: sign.oidThresholdBoolean,
			  signOidThresholdUp: sign.oidThresholdUp,
			  signOidThresholdDown: sign.oidThresholdDown,
			  signType: sign.signType,
			  alertName: sign.name,
			  alertSummary: sign.summary,
			  resolSummary: sign.resolSummary,
			  alertSeverity: sign.severity,
			  alertScope: sign.scope,
			  openTicket: sign.openTicket,
			};
			if (actualSigns[index]) {
			  actualSigns[index] = newSign;
			} else {
			  actualSigns.push(newSign);
			}
		    setSignsConfigured(actualSigns);			
		  }
		  return actualSigns;
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const getStyles = (theme: NetMonitorTheme2) => ({
  section: css`
    margin: 4px;
  `,
});
